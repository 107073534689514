import { relations } from 'drizzle-orm'
import { boolean, integer, jsonb, pgEnum, text, varchar } from 'drizzle-orm/pg-core'
import { createSelectSchema, createInsertSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, timestamps } from '@epostbox/shared/database'

import { DocumentLabelID } from '../_ids'
import { costCenter } from '../cost-center'

import { authSchema } from './.auth-schema'
import { $workspaceId, WorkspaceID } from './_ids'
import { samlProviders } from './saml-provider'
import { scimTokens } from './scim-token'
import { ssoDomains } from './sso-domain'
import { workspaceMembers } from './workspace-member'

export const EinvoiceType = z.enum(['ZUGFERD', 'FACTURX'])
export type EinvoiceType = z.infer<typeof EinvoiceType>

export const EinvoiceChannel = z.enum(['NOLAS', 'POST', 'NOLAS_AND_POST'])
export type EinvoiceChannel = z.infer<typeof EinvoiceChannel>

export const eInvoiceTypeEnum = pgEnum('einvoice_type', [EinvoiceType.Enum.FACTURX, EinvoiceType.Enum.ZUGFERD])
export const eInvoiceChannelEnum = pgEnum('einvoice_channel', [
  EinvoiceChannel.Enum.NOLAS,
  EinvoiceChannel.Enum.POST,
  EinvoiceChannel.Enum.NOLAS_AND_POST,
])
export const TaxKey = z
  .object({
    key: z.string(),
    description: z.string().optional(),
    value: z.number(),
  })
  .openapi({
    type: 'object',
    description: 'A key-value pair for tax configuration',
  })

export const WorkspaceSettings = z.object({
  taxKeys: z.array(TaxKey).optional().openapi({
    type: 'array',
    description: 'An optional array of tax keys',
  }),
})
export type WorkspaceSettings = z.infer<typeof WorkspaceSettings>
const defaultWorkspaceSettings = {
  taxKeys: [
    {
      key: '0',
      description: 'ohne Steuer',
      value: 0,
    },
    {
      key: '1',
      description: 'halb',
      value: 7,
    },
    {
      key: '2',
      description: 'voll',
      value: 19,
    },
  ],
}
export const workspaces = authSchema.table('workspaces', {
  id: id<WorkspaceID>($workspaceId.prefix),
  name: varchar('name').notNull(),
  approved: boolean('approved').default(false),
  eInvoiceType: eInvoiceTypeEnum('e_invoice_type'),
  eInvoiceChannel: eInvoiceChannelEnum('e_invoice_channel'),
  documentLabelsId: text('document_labels_id').$type<DocumentLabelID>(),
  freeWorkflowRuns: integer('free_workflow_runs').notNull().default(30),
  settings: jsonb('settings').$type<WorkspaceSettings>().default(defaultWorkspaceSettings),
  ...timestamps(),
})

export const WorkspaceRecord = createSelectSchema(workspaces, {
  id: WorkspaceID,
  documentLabelsId: DocumentLabelID,
  settings: WorkspaceSettings,
})
export interface WorkspaceRecord extends z.infer<typeof WorkspaceRecord> {}

export const WorkspaceRecordCreate = createInsertSchema(workspaces).omit({
  id: true,
  deletedAt: true,
  updatedAt: true,
  createdAt: true,
})
export interface WorkspaceRecordCreate extends z.infer<typeof WorkspaceRecordCreate> {}

export const workspaceRelations = relations(workspaces, ({ many }) => ({
  samlProviders: many(samlProviders),
  ssoDomains: many(ssoDomains),
  scimTokens: many(scimTokens),
  members: many(workspaceMembers),
  costCenters: many(costCenter),
}))
