import { boolean, index, integer, jsonb, pgEnum, pgTable, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, ltree, refId, timestamps } from '@epostbox/shared/database'

import { $documentAssignmentId, DocumentAssignmentID, DocumentID, WorkflowRunID } from './_ids'
import { UserID, WorkspaceID } from './auth/_ids'
import { documents } from './documents'
import { AccessPolicy } from './workflow'
import { workflowRun } from './workflow-run'

export const spaceEnum = pgEnum('space_enum', ['DRAFTS', 'INBOX', 'SENT', 'TRASH', 'FOLDER'])
export type Space = (typeof spaceEnum.enumValues)[number]

export enum Channel {
  Nolas = 'NOLAS',
  Email = 'EMAIL',
  Post = 'POST',
  SFTP = 'SFTP',
  REST = 'REST',
  PRINTER = 'PRINTER',
}
export const channelEnum = pgEnum('channel_enum', [
  Channel.Nolas,
  Channel.Email,
  Channel.Post,
  Channel.SFTP,
  Channel.REST,
  Channel.PRINTER,
])

export const ChannelEnum = z.enum(channelEnum.enumValues)

export const assignmentStatusEnum = pgEnum('assn_status_enum', [
  'SEND_PENDING',
  'SEND_FAILED',
  'SENT',
  'NONE',
  'CONVERSION_FAILED', // X-Invoice couldn't be generated
  'TRANSFERED_TO_PROD', // Post - Sent to Printing
  'IN_PRODUCTION', // Post - Currently printing ??
  'PRODUCED', // Post - Successfully printed
])
export type AssignmentStatus = (typeof assignmentStatusEnum.enumValues)[number]

const EmailRecipient = z.object({ userId: UserID.optional(), email: z.string().email() })
export type EmailRecipient = z.infer<typeof EmailRecipient>
const PostalRecipient = z.object({ zip: z.string(), city: z.string() })
export type PostalRecipient = z.infer<typeof PostalRecipient>
const NolasRecipient = z.object({
  userId: UserID,
  name: z.string().nullable().optional(),
  email: z.string().email().nullable().optional(),
})
export type NolasRecipient = z.infer<typeof NolasRecipient>
const Recipient = EmailRecipient.or(PostalRecipient).or(NolasRecipient)
export type Recipient = z.infer<typeof Recipient>

export const Sender = z.object({
  userId: UserID.optional(),
  email: z.string().email().optional(),
  name: z.string().optional().nullable(),
})
export type Sender = z.infer<typeof Sender>

export const documentsAssignment = pgTable(
  'document_assignment',
  {
    id: id<DocumentAssignmentID>($documentAssignmentId.prefix),
    documentId: refId<DocumentID>('document_id')
      .references(() => documents.id, { onDelete: 'cascade' })
      .notNull(),
    workspaceId: text('workspace_id').notNull().$type<WorkspaceID>(),
    createdBy: text('created_by').notNull().$type<UserID>(),
    space: spaceEnum('space').notNull().default('DRAFTS'),
    folder: ltree('folder'),
    channel: channelEnum('channel').notNull(),
    accessPolicy: jsonb('access_policy').$type<AccessPolicy>(),
    workflowRunId: refId<WorkflowRunID>('workflow_run_id').references(() => workflowRun.id, { onDelete: 'set null' }),
    status: assignmentStatusEnum('status').notNull().default('NONE'),
    recipients: jsonb('recipients').$type<Recipient[]>().notNull().default([]),
    sender: jsonb('sender').$type<Sender>(),
    isApproved: boolean('is_approved'),

    // An ID generated by "printing.epostbox.de" upon sending a document.
    // This will be needed later in querying the document's status (e.g. 135844)
    printDocId: integer('printing_doc_id'),

    hidden: boolean('hidden').default(false).notNull(),
    ...timestamps(),
  },
  table => ({
    workspaceid_idx: index('workspaceid_idx').on(table.workspaceId),
  })
)

export const DocumentAssignmentRecord = createSelectSchema(documentsAssignment, {
  id: DocumentAssignmentID,
  documentId: DocumentID,
  workspaceId: WorkspaceID,
  createdBy: UserID,
  workflowRunId: WorkflowRunID,
  recipients: z.array(Recipient),
  sender: Sender,
  accessPolicy: AccessPolicy.optional(),
  printDocId: z.number().optional(),
})

export type DocumentAssignmentRecord = z.infer<typeof DocumentAssignmentRecord>

export const DocumentAssignmentRecordCreate = createInsertSchema(documentsAssignment, {
  id: DocumentAssignmentID,
  documentId: DocumentID,
  workspaceId: WorkspaceID,
  createdBy: UserID,
  workflowRunId: WorkflowRunID,
  recipients: z.array(Recipient),
  sender: Sender,
})

export type DocumentAssignmentRecordCreate = z.infer<typeof DocumentAssignmentRecordCreate>
