import { pgTable, primaryKey, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $documentIdentifierId, DocumentIdentifierID } from './_ids'
import { WorkspaceID } from './auth/_ids'

export const documentIdentifier = pgTable(
  'document-identifier',
  {
    id: pkPart<DocumentIdentifierID>($documentIdentifierId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    name: text('name').notNull(),
    identifier: text('identifier').notNull(),
    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
  })
)

const DocumentIdentifierSchema = {
  id: DocumentIdentifierID,
  workspaceId: WorkspaceID,
}

export const DocumentIdentifierRecord = createSelectSchema(documentIdentifier, DocumentIdentifierSchema)
export type DocumentIdentifierRecord = z.infer<typeof DocumentIdentifierRecord>

export const DocumentIdentifierRecordCreate = createInsertSchema(documentIdentifier, DocumentIdentifierSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export type DocumentIdentifierRecordCreate = z.infer<typeof DocumentIdentifierRecordCreate>

export const ImportDocumentIdentifiers = z.object({
  name: z.string(),
  identifier: z.string(),
})

export type ImportDocumentIdentifiers = z.infer<typeof ImportDocumentIdentifiers>
