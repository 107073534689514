<script setup lang="ts">
import { SelectItem, SelectItemIndicator, type SelectItemProps, SelectItemText } from 'radix-vue'

import { selectItemTextVariants, selectItemVariants } from '.'

const props = defineProps<SelectItemProps & { class?: string }>()
</script>

<template>
  <SelectItem v-bind="props" :class="selectItemVariants({ class: $attrs.class ?? '' })">
    <span class="absolute right-4 flex h-3.5 w-3.5 items-center justify-center">
      <SelectItemIndicator>
        <INolasCheck class="h-5 w-5 text-brand-600" />
      </SelectItemIndicator>
    </span>

    <SelectItemText :class="selectItemTextVariants()">
      <slot />
    </SelectItemText>
  </SelectItem>
</template>
