import { cva, type VariantProps } from 'class-variance-authority'
import type { SelectRootProps, SelectTriggerProps } from 'radix-vue'

import { type ErrorMessage } from '../form'

export { default as PhoneSelect } from './phone-select.vue'
export { default as SelectContent } from './select-content.vue'
export { default as SelectGroup } from './select-group.vue'
export { default as SelectItemText } from './select-item-text.vue'
export { default as SelectItem } from './select-item.vue'
export { default as SelectLabel } from './select-label.vue'
export { default as SelectSeparator } from './select-separator.vue'
export { default as SelectTriggerIcon } from './select-trigger-icon.vue'
export { default as SelectTrigger } from './select-trigger.vue'
export { default as SelectValue } from './select-value.vue'
export { default as Select } from './select.vue'
export interface Item {
  label: string
  value: string
  e2e?: string
  unicode?: string
  dial_code?: string
  icon?: unknown
  type?: string
}

export interface ItemsByGroup {
  title: string
  items: Item[]
}

export const getItem = (items: Item[], value: string | undefined) => {
  if (!value) return
  return items.find(item => item.value === value)
}

export const valueToVariant = (value: unknown) => (value ? 'yes' : 'no')

export const selectVariants = cva(
  'flex items-center outline-none text-sm font-medium text-gray-900 leading-5 overflow-hidden truncate group-hover:bg-gray-200 disabled:text-gray-550 group relative',
  {
    variants: {
      color: {
        beige: 'bg-gray-100',
        white: 'bg-white',
      },
      size: {
        sm: 'h-8 w-24 px-1',
        md: 'h-13 w-full px-4',
      },
      label: {
        yes: 'pt-5',
        no: '',
      },
      disabled: {
        yes: 'pointer-events-none',
        no: '',
      },
    },
    defaultVariants: {
      color: 'beige',
      size: 'md',
    },
  }
)

export const selectWrapperVariants = cva(
  'group border-2 overflow-hidden relative border-transparent focus-within:border-primary-500 rounded-lg hover:bg-gray-200 group-hover:border-gray-200',
  {
    variants: {
      color: {
        beige: 'bg-gray-100',
        white: 'bg-white',
      },
      size: {
        sm: 'h-8 w-24',
        md: 'h-13 w-full',
      },
    },
    defaultVariants: {
      color: 'beige',
      size: 'md',
    },
  }
)

export const selectContentVariants = cva(
  'relative z-[999] min-w-[8rem] overflow-hidden rounded-xl bg-white text-gray-900 shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
  {
    variants: {
      position: {
        popper:
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        'item-aligned': '',
      },
    },
    defaultVariants: {
      position: 'popper',
    },
  }
)

export const selectViewportVariants = cva('p-0', {
  variants: {
    position: {
      popper: 'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
      'item-aligned': '',
    },
  },
  defaultVariants: {
    position: 'popper',
  },
})

export const selectItemVariants = cva(
  'relative flex cursor-default select-none items-center rounded-lg p-3.5 pl-4 text-sm font-medium leading-4.5 text-gray-900 outline-none hover:bg-gray-150 data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-150 data-[highlighted]:text-gray-900 data-[disabled]:opacity-50'
)

export const selectItemTextVariants = cva(
  'flex w-80 items-center gap-2.5 overflow-hidden truncate text-ellipsis pr-6 font-medium leading-4.5 !text-gray-900'
)

export const selectLabelVariants = cva(
  'pointer-events-none absolute left-4 origin-[0] transform font-medium text-gray-600 duration-300 z-[1]',
  {
    variants: {
      modelValue: {
        yes: '-translate-y-3 text-xs',
        no: 'translate-y-0 text-sm',
      },
      disabled: {
        yes: 'pointer-events-none text-gray-450',
        no: '',
      },
      error: {
        yes: 'text-error-650',
        no: '',
      },
      moveOnFocus: {
        yes: 'group-focus-within:-translate-y-3 group-focus-within:text-xs',
        no: '',
      },
    },
    defaultVariants: {
      moveOnFocus: 'no',
    },
  }
)

export type Variants = VariantProps<typeof selectVariants>

export interface SelectProps extends SelectRootProps {
  color?: Variants['color']
  size?: Variants['size']
  placeholder?: string
  label?: string
  items: Item[] | ItemsByGroup[]
  error?: ErrorMessage
  translatable?: boolean
  contentClasses?: string
  triggerClasses?: string
  wrapperClasses?: string
  iconClasses?: string
  dataE2e?: string
  required?: boolean
}

export interface CustomSelectTriggerProps extends SelectTriggerProps {
  name: string
  label?: string
  class?: string
  invalid?: boolean
  open: boolean
  error?: ErrorMessage
  value?: string
  contentClasses?: string
  triggerClasses?: string
  wrapperClasses?: string
  iconClasses?: string
  dataE2e?: string
  color?: Variants['color']
  size?: Variants['size']
  required?: boolean
  hideErrorIcon?: boolean
  withSearch?: boolean
}
