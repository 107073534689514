import { pgTable, primaryKey, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $glAccountId, GLAccountID } from './_ids'
import { WorkspaceID } from './auth/_ids'

export const glAccount = pgTable(
  'gl-account',
  {
    id: pkPart<GLAccountID>($glAccountId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    name: text('name').notNull(),
    number: text('number').notNull(),
    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
  })
)

const GLAccountSchema = {
  id: GLAccountID,
  workspaceId: WorkspaceID,
}

export const GLAccountRecord = createSelectSchema(glAccount, GLAccountSchema)
export type GLAccountRecord = z.infer<typeof GLAccountRecord>

export const GLAccountRecordCreate = createInsertSchema(glAccount, GLAccountSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export type GLAccountRecordCreate = z.infer<typeof GLAccountRecordCreate>

export const ImportGLAccount = z.object({
  name: z.string(),
  number: z.string(),
})

export type ImportGLAccount = z.infer<typeof ImportGLAccount>
