import { useSessionStorage } from '@vueuse/core'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import AddressBook from '~icons/epb-icons/address-book'
import AttachmentsFilled from '~icons/epb-icons/attachments-filled'
import Calendar from '~icons/epb-icons/calendar'
import Interfaces from '~icons/epb-icons/console'
import DataExchangeFilled from '~icons/epb-icons/data-exchange-filled'
import CurrencyEuroCircleFilled from '~icons/epb-icons/currency-euro-circle-filled'
import Draft from '~icons/epb-icons/draft'
import Inbox from '~icons/epb-icons/inbox'
import LetterheadsFilled from '~icons/epb-icons/letterheads-filled'
import Office from '~icons/epb-icons/office'
import Send from '~icons/epb-icons/send'
import SettingsCog from '~icons/epb-icons/settings-cog'
import SignaturesFilled from '~icons/epb-icons/signatures-filled'
import SSO from '~icons/epb-icons/sso'
import TextTemplatesFilled from '~icons/epb-icons/text-templates-filled'
import Trash from '~icons/epb-icons/trash'
import Workbench from '~icons/epb-icons/workbench'
import Workflows from '~icons/epb-icons/workflows'

import { useDocumentsCounts } from '@composables/search/use-search'

// const excludeOnTest = import.meta.env.VITE_APP_STAGE !== 'test'

type BaseMenuItem = {
  id: string
  name: string
  icon?: unknown
  badge?: number
  path: RouteLocationRaw
}

type ModuleMenuItem = BaseMenuItem & {
  type: 'module'
  children: (MenuItem | null)[]
}

type PageMenuItem = BaseMenuItem & {
  type: 'page'
}

type SpacerMenuItem = {
  id?: string
  name?: string
  type: 'spacer'
}

export type MenuItem = ModuleMenuItem | PageMenuItem | SpacerMenuItem

export function useNavigation() {
  const { documentsCounts } = useDocumentsCounts()

  const { t } = useI18n()

  const router = useRouter()
  const route = useRoute()
  const menuItems = computed<MenuItem[]>(() => [
    {
      id: 'workbench',
      name: t('page_name.app.workbench'),
      path: '/workbench',
      icon: Workbench,
      type: 'module',
      children: [
        {
          id: 'workbench-inbox',
          name: t('page_name.app.workbench-inbox'),
          icon: Inbox,
          path: '/workbench/inbox',
          type: 'page',
          badge: documentsCounts.value.inbox,
        },
        {
          id: 'workbench-drafts',
          name: t('page_name.app.workbench-drafts'),
          icon: Draft,
          path: '/workbench/drafts',
          type: 'page',
          badge: documentsCounts.value.drafts,
        },
        {
          id: 'workbench-sent',
          name: t('page_name.app.workbench-sent'),
          icon: Send,
          path: '/workbench/sent',
          type: 'page',
          badge: documentsCounts.value.sent,
        },
        {
          id: 'workbench-trash',
          name: t('page_name.app.workbench-trash'),
          icon: Trash,
          path: '/workbench/trash',
          type: 'page',
          badge: documentsCounts.value.trash,
        },
        { type: 'spacer' },
        {
          id: 'workbench-address-book',
          name: t('page_name.app.workbench-address-book'),
          icon: AddressBook,
          path: '/workbench/address-book',
          type: 'page',
        },
        {
          id: 'workbench-calendar',
          name: t('page_name.app.workbench-calendar'),
          icon: Calendar,
          path: '/workbench/calendar',
          type: 'page',
        },
        {
          id: 'workbench-office',
          name: t('page_name.app.workbench-office'),
          icon: Office,
          path: '/workbench/my-office',
          type: 'page',
        },
      ],
    },
    { type: 'spacer' },
    {
      id: 'workspace-settings',
      name: t('page_name.app.workspace-settings'),
      path: '/settings',
      type: 'page',
    },
    {
      id: 'workspace-administration',
      name: t('page_name.app.administration'),
      path: '/administration',
      type: 'module',
      children: [
        {
          id: 'administration-general',
          name: t('page_name.app.administration-general'),
          icon: SettingsCog,
          path: '/administration/general',
          type: 'page',
        },
        {
          id: 'administration-interfaces',
          name: t('page_name.app.administration-interfaces'),
          icon: Interfaces,
          path: '/administration/interfaces',
          type: 'page',
        },
        {
          id: 'administration-workflows',
          name: t('page_name.app.administration-workflows'),
          icon: Workflows,
          path: '/administration/workflows',
          type: 'page',
        },
        {
          id: 'administration-data-exchange',
          name: t('page_name.app.administration-data-exchange'),
          icon: DataExchangeFilled,
          path: '/administration/data-exchange',
          type: 'page',
        },
        {
          id: 'administration-cost-centers',
          name: t('page_name.app.administration-cost-centers'),
          icon: CurrencyEuroCircleFilled,
          path: '/administration/cost-centers',
          type: 'page',
        },
        {
          id: 'administration-sso-settings',
          name: t('page_name.app.administration-sso'),
          icon: SSO,
          path: '/administration/sso-settings',
          type: 'page',
        },
        { type: 'spacer' },
        {
          id: 'administration-signatures',
          name: t('page_name.app.administration-signatures'),
          icon: SignaturesFilled,
          path: '/administration/signatures',
          type: 'page',
        },
        {
          id: 'administration-letterheads',
          name: t('page_name.app.administration-letterheads'),
          icon: LetterheadsFilled,
          path: '/administration/letterheads',
          type: 'page',
        },
        {
          id: 'administration-attachments',
          name: t('page_name.app.administration-attachments'),
          icon: AttachmentsFilled,
          path: '/administration/attachments',
          type: 'page',
        },
        {
          id: 'administration-text-templates',
          name: t('page_name.app.administration-text-templates'),
          icon: TextTemplatesFilled,
          path: '/administration/text-templates',
          type: 'page',
        },
      ],
    },
    {
      id: 'workspace-download',
      name: t('page_name.app.workspace-download'),
      path: '/download',
      type: 'page',
    },
    { type: 'spacer' },
    {
      id: 'workspace-tutorials',
      name: t('page_name.app.workspace-tutorials'),
      path: '/tutorials',
      type: 'page',
    },
    {
      id: 'workspace-support',
      name: t('page_name.app.workspace-support'),
      path: '/support',
      type: 'page',
    },
  ])

  // Menu fold state (unfolded by default)
  const isMenuUnfolded = useSessionStorage('menu-unfolded-state', true)

  // Switch menu state (closed by default)
  const isSwitchMenuOpen = ref(false)

  const currentModuleId = ref()
  const currentItemId = ref()
  const langPrefix = useSessionStorage<string>('langPrefix', '')

  const supportedPrefixes = ['', '/de']

  const detectPrefix = (path: string): string => {
    const match = supportedPrefixes.find(prefix => prefix !== '' && path.startsWith(prefix))
    return match || ''
  }

  const stripPrefix = (path: string): string => {
    const prefix = detectPrefix(path)
    return prefix ? path.slice(prefix.length) : path
  }

  const findItemByPath = (items: MenuItem[], path: string): MenuItem | null => {
    const strippedPath = stripPrefix(path)
    for (const item of items) {
      if (item?.type === 'spacer') continue
      if (item?.type === 'module' && item.children) {
        const found = findItemByPath(item.children as MenuItem[], strippedPath)
        if (found) return found
      }
      if (item?.type === 'page' && typeof item.path === 'string' && strippedPath.startsWith(stripPrefix(item.path))) {
        return item
      }
    }

    return null
  }

  const updateCurrentIds = () => {
    const detectedPrefix = detectPrefix(route.path)
    if (detectedPrefix !== langPrefix.value) {
      langPrefix.value = detectedPrefix
    }

    const currentItem = findItemByPath(menuItems.value, route.path)
    if (currentItem) {
      if (currentItem.type === 'page') {
        const parentModule = menuItems.value.find(
          item => item.type === 'module' && item.children?.some(child => child?.id === currentItem.id)
        )
        currentModuleId.value = parentModule?.id ?? currentItem.id
        currentItemId.value = currentItem.id
      } else if (currentItem.type === 'module') {
        currentModuleId.value = currentItem.id
        currentItemId.value = currentItem.children?.[0]?.id ?? currentItem.id
      }
    } else {
      if (route.meta?.moduleId && route.meta?.parent) {
        currentModuleId.value = route.meta.moduleId ?? null
        currentItemId.value = route.meta.parent ?? null
      } else {
        currentModuleId.value = null
        currentItemId.value = null
      }
    }
  }

  // Update IDs whenever the route changes
  watch(() => route.path, updateCurrentIds, { immediate: true })

  const currentModule = computed(() => {
    return menuItems.value.find(item => item.type !== 'spacer' && item.id === currentModuleId.value) || null
  })

  const currentItem = computed(() => {
    const module = currentModule.value
    if (module?.type === 'module') {
      return (
        module.children.find(item => item?.type !== 'spacer' && item?.id === currentItemId.value) ||
        module.children[0] ||
        null
      )
    }
    return module
  })

  const currentSubMenu = computed(() => (currentModule.value?.type === 'module' && currentModule.value.children) || [])

  const setCurrentModule = (module: MenuItem) => {
    if (module.type === 'spacer') return
    if (module.id === currentModuleId.value) {
      closeSwitchMenu()
    }

    if (module.type === 'module' && module.children[0]?.type === 'page') {
      router.push(`${langPrefix.value}${module.children[0].path}`)
    } else {
      router.push(`${langPrefix.value}${module.path}`)
    }
  }

  const setCurrentItem = (item: MenuItem) => {
    if (item.type === 'spacer') return
    router.push(`${langPrefix.value}${item.path}`)
  }

  const toggleMenuFold = () => {
    isMenuUnfolded.value = !isMenuUnfolded.value
  }

  const toggleSwitchMenu = () => {
    isSwitchMenuOpen.value = !isSwitchMenuOpen.value
  }

  const closeSwitchMenu = () => {
    isSwitchMenuOpen.value = false
  }

  return {
    menuItems,
    currentSubMenu,
    isMenuUnfolded,
    isSwitchMenuOpen,
    toggleMenuFold,
    toggleSwitchMenu,
    currentModule,
    currentItem,
    setCurrentModule,
    setCurrentItem,
  }
}
