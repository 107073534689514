<script setup lang="ts">
import { inject } from 'vue'

import { cn, groupStyleClasses } from '../../utils'
import { Loader } from '../loader'

import { iconButtonVariants, type IconButtonProps } from '.'

withDefaults(defineProps<IconButtonProps>(), {
  as: 'button',
  loading: false,
  rounded: false,
})
const isInputGroup = inject<boolean>('input-group', false)
</script>

<template>
  <component
    :is="as"
    :disabled="$attrs.disabled"
    :class="
      cn(
        iconButtonVariants({ variant, size }),
        groupStyleClasses(isInputGroup!),
        $attrs.class ?? '',
        rounded ? 'rounded-full' : 'rounded-lg',
        loading ? 'pointer-events-none cursor-wait' : '',
        disabled ? 'pointer-events-none text-gray-450' : ''
      )
    "
  >
    <Loader v-if="loading" />
    <slot v-if="!loading" />
  </component>
</template>
