import { GetMyContact } from '@services/keyflowz-api'
import { useQuery } from '@tanstack/vue-query'
import { useQueryClient } from '@tanstack/vue-query'
import { Ref } from 'vue'

import { ServiceError } from '@epostbox/shared/errors'

import { contactsQueryKey } from '@composables/search/use-contact-search'
import { useUser } from '@composables/use-user'

const fetchMyContact = async (workspaceId: string) => {
  const { response, data, error } = await GetMyContact({
    params: { path: { workspaceId } },
  })

  if (!response.ok || !data) {
    throw ServiceError.fromResponse(error)
  }
  return data
}

export function useMyContact() {
  const { user } = useUser()
  const queryClient = useQueryClient()

  const {
    data: myContact,
    error,
    isPending,
    ...queryRest
  } = useQuery({
    queryKey: contactsQueryKey._def,
    retry: 2,
    enabled: () => !!user.value,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    staleTime: 1000,
    queryFn: async () => fetchMyContact(user.value!.workspaceId),
  })
  queryClient.invalidateQueries({ queryKey: contactsQueryKey._def })

  return { myContact, isPending, error: error as Ref<ServiceError | null>, ...queryRest }
}
