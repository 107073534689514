import { pgTable, text, jsonb, integer } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { id, refId, timestamps } from '@epostbox/shared/database'

import { $busEventId, BusEventID, CostCenterID } from './_ids'
import { costCenter } from './cost-center'

type PayloadValue = string | number | object | PayloadValue[]
type EventPayloadType = Record<string, PayloadValue>

export const busEvents = pgTable('bus_events', {
  id: id<BusEventID>($busEventId.prefix),

  // The name of event;
  // Follows the pattern "[namespace]:[action]" (e.g. "documents:remove", "sftp-user:create")
  // See "[Keyflowz-Core]/events/index.ts"
  name: text('name').notNull(),

  // Payload of current event, if provided. Empty object if no payload
  payload: jsonb('payload').$type<EventPayloadType>().notNull().default({}),

  // This column will keep track of the generated costs that each event generated
  eventPaymentTokens: integer('payment_tokens').notNull().default(0),

  // Cost Center for generated cost
  costCenter: refId<CostCenterID>('cost_center').references(() => costCenter.id, { onDelete: 'no action' }),

  ...timestamps(),
})

export const BusEventRecord = createSelectSchema(busEvents, {
  id: BusEventID,
  name: z.string(),
  payload: z.any().default({}), // z.object<EventPayloadType>(???).default({}),
  costCenter: CostCenterID,
  createdAt: z.date(),
})
export type BusEventRecord = z.infer<typeof BusEventRecord>

export const BusEventRecordCreate = createInsertSchema(busEvents, {
  id: BusEventID,
  name: z.string().min(3),
  payload: z.any().default({}),
  costCenter: CostCenterID,
  createdAt: z.date().default(new Date()),
})
export type BusEventRecordCreate = z.infer<typeof BusEventRecordCreate>
