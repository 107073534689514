<script setup lang="ts">
import { useVModel } from '@vueuse/core'

import { cn } from '../../utils'

import { textareaVariants, textareaLabelVariants, textareaWrapperVariants, TextAreaProps } from './'

interface ExtendedTextAreaProps extends TextAreaProps {
  resizable?: boolean
  required?: boolean
}

const props = withDefaults(defineProps<ExtendedTextAreaProps>(), {
  resizable: true,
})

const emits = defineEmits<{ (e: 'update:modelValue', payload: string | number): void }>()
const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
})
</script>

<template>
  <div
    :class="
      cn(
        'relative flex w-full',
        textareaWrapperVariants({ color }),
        disabled ? 'cursor-not-allowed opacity-60' : 'cursor-text opacity-100',
        error ? 'border-error-650' : ''
      )
    "
  >
    <textarea
      :id="name"
      v-model="modelValue"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      placeholder=""
      :class="
        cn(
          'textarea',
          textareaVariants({ color }),
          disabled ? 'pointer-events-none' : '',
          !resizable && 'textarea-field-sizing resize-none',
          inputClass
        )
      "
    />
    <label
      :for="name"
      :class="cn(textareaLabelVariants(), disabled ? 'pointer-events-none' : '', error ? 'text-error-650' : '')"
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
  </div>
</template>

<style scoped>
.textarea {
  scrollbar-gutter: stable;
  field-sizing: content;
  min-height: 6em;
  width: 100%;
}

.textarea-field-sizing {
  field-sizing: fixed !important;
}
</style>
