<script setup lang="ts">
import { useNavigation, type MenuItem } from '@layouts/navigation.ts'
import { computed, useTemplateRef } from 'vue'

import { ExpandableSearchInput } from '@epostbox/ui/input'

import Heading from '../../components/heading/heading.vue'
import { AppBar } from '../app-bar'

import Header from './header.vue'
import NavigationItem from './navigation-item.vue'

const {
  menuItems,
  currentItem,
  currentModule,
  currentSubMenu,
  setCurrentModule,
  setCurrentItem,
  isMenuUnfolded,
  toggleMenuFold,
  toggleSwitchMenu,
  isSwitchMenuOpen,
} = useNavigation()

defineEmits(['page-change'])

const sidebar = useTemplateRef('app-bar-navigation-bar')
const search = defineModel<string | undefined>()
const width = defineModel<number>('width', { default: 312 })

const groupedMenuItems = computed(() => {
  const result: MenuItem[][] = []
  let currentArray: MenuItem[] = []

  for (const [index, item] of menuItems.value.entries()) {
    if (item.type === 'spacer') {
      if (currentArray.length > 0) {
        result.push(currentArray)
        currentArray = []
      }
    } else {
      currentArray.push(item)
    }

    if (index === menuItems.value.length - 1 && currentArray.length > 0) {
      result.push(currentArray)
    }
  }

  return result
})

function onStartResize(event: MouseEvent) {
  requestAnimationFrame(() => {
    if (sidebar.value) {
      const newWidth = event.clientX - sidebar.value.getBoundingClientRect().left
      const minWidth = 180
      const maxWidth = window.innerWidth - 50

      if (newWidth >= minWidth && newWidth <= maxWidth) {
        width.value = newWidth
      }
    }
  })
}

function onStopResize() {
  if (sidebar.value) {
    sidebar.value.style.transitionProperty = 'all' // add back the animation
  }

  document.body.classList.remove('no-user-select')
  document.removeEventListener('mousemove', onStartResize)
  document.removeEventListener('mouseup', onStopResize)
}

function onResizerMouseDown() {
  if (sidebar.value) {
    sidebar.value.style.transitionProperty = 'none' // remove the animation, otherwise it would make the resize lag
    document.body.classList.add('no-user-select') // disable the user select, otherwise text would be selected when resizing
    document.addEventListener('mousemove', onStartResize)
    document.addEventListener('mouseup', onStopResize)
  }
}

const resizerDisabled = import.meta.env.VITE_APP_STAGE === 'test' || import.meta.env.VITE_APP_STAGE === 'production'
</script>

<template>
  <AppBar />
  <div
    ref="app-bar-navigation-bar"
    :class="[
      'navbar z-50 h-screen overflow-hidden bg-gray-100',
      'transition-all duration-300 ease-in-out',
      isMenuUnfolded ? 'visible' : 'invisible',
    ]"
    :style="{
      position: 'relative',
      width: isMenuUnfolded ? `${width}px` : '80px',
      left: isMenuUnfolded ? 0 : '-200%',
      height: '100%',
      zIndex: isMenuUnfolded ? 50 : -1,
    }"
    data-e2e="app-bar-navigation-bar"
  >
    <div class="relative flex h-full flex-col pt-3" :style="{ 'min-width': `${width}px` }">
      <Header
        :is-switch-menu-open="isSwitchMenuOpen"
        :show-switcher="currentModule?.type === 'module'"
        class="px-4 py-0.5"
        @toggle-switch-menu="toggleSwitchMenu"
        @toggle-menu-fold="toggleMenuFold"
      >
        <template v-if="!isSwitchMenuOpen && currentModule?.type === 'module'" #title>
          <Heading as="h3" size="sm" class="ml-2" :data-e2e="`app-bar-navigation-bar-title-${currentModule?.id}`">
            {{ currentModule?.name }}
          </Heading>
        </template>
      </Header>

      <div class="navbar-content relative pl-4 pr-2">
        <Transition name="fade" mode="out-in">
          <div
            v-if="isSwitchMenuOpen || currentModule?.type === 'page' || currentModule === null"
            class="mt-8 flex flex-col gap-2"
          >
            <div v-for="itemList in groupedMenuItems" :key="itemList[0].id" class="rounded-lg bg-white py-1">
              <NavigationItem
                v-for="item in itemList"
                :key="item.id"
                :is-selected="currentModule?.id === item.id"
                :item="item"
                has-right-chevron
                @click="setCurrentModule(item)"
              />
            </div>
          </div>

          <div v-else-if="currentModule?.type === 'module'" id="navmenu" class="mt-0.5 pt-2">
            <ExpandableSearchInput
              v-if="currentModule?.id !== 'administration'"
              v-show="false"
              v-model="search"
              hide-clear-button
              color="gray"
              :debounce-time="400"
              :placeholder="$t('common.search')"
              :show-dot="false"
              :data-e2e="`app-bar-navigation-bar-search-${currentModule?.id}`"
            />
            <div class="mt-6">
              <div v-for="(item, id) in currentSubMenu" :key="id">
                <NavigationItem
                  v-if="item && currentItem"
                  :is-selected="currentItem.id === item.id"
                  :item="item"
                  @click.stop="setCurrentItem(item)"
                />
              </div>
              <slot name="groups" />
            </div>
          </div>
        </Transition>
      </div>

      <slot name="footer" />
    </div>

    <div
      v-if="!resizerDisabled"
      class="absolute right-0 top-0 h-full w-2 hover:cursor-col-resize"
      @mousedown="onResizerMouseDown"
    ></div>
  </div>
</template>

<style>
.resizable-component > .resizable-r,
.resizable-component > .resizable-l {
  @apply cursor-col-resize !important;
}
.navbar-content {
  scrollbar-gutter: stable;
}

.no-user-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
</style>
