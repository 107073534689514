<script setup lang="ts">
import {
  SelectContent,
  type SelectContentEmits,
  type SelectContentProps,
  SelectPortal,
  SelectViewport,
  useForwardPropsEmits,
} from 'radix-vue'

import { selectContentVariants, selectViewportVariants } from '.'

const props = withDefaults(defineProps<SelectContentProps & { class?: string }>(), {
  position: 'popper',
  sideOffset: 4,
  class: undefined,
})
const emits = defineEmits<SelectContentEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <SelectPortal>
    <SelectContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="selectContentVariants({ class: props.class, position: props.position })"
    >
      <SelectViewport :class="selectViewportVariants({ position: props.position })">
        <slot />
      </SelectViewport>
    </SelectContent>
  </SelectPortal>
</template>
