import { pgTable, primaryKey, text } from 'drizzle-orm/pg-core'
import { createInsertSchema, createSelectSchema } from 'drizzle-zod'
import { z } from 'zod'

import { pkPart, refId, timestamps } from '@epostbox/shared/database'

import { $creditorId, CreditorID } from './_ids'
import { WorkspaceID } from './auth/_ids'

export const creditor = pgTable(
  'creditors',
  {
    id: pkPart<CreditorID>($creditorId.prefix),
    workspaceId: refId<WorkspaceID>('workspace_id').notNull(),

    name: text('name').notNull(),
    number: text('number').notNull(),
    city: text('city'),
    zipCode: text('zip_code'),
    addressLine: text('address_line'),
    ...timestamps(),
  },
  table => ({
    pk: primaryKey({ columns: [table.id, table.workspaceId] }),
  })
)

const CreditorSchema = {
  id: CreditorID,
  workspaceId: WorkspaceID,
}

export const CreditorRecord = createSelectSchema(creditor, CreditorSchema)
export type CreditorRecord = z.infer<typeof CreditorRecord>

export const CreditorRecordCreate = createInsertSchema(creditor, CreditorSchema).omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  deletedAt: true,
})
export type CreditorRecordCreate = z.infer<typeof CreditorRecordCreate>

export const ImportCreditor = z.object({
  name: z.string(),
  number: z.string(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  addressLine: z.string().optional(),
})

export type ImportCreditor = z.infer<typeof ImportCreditor>
