import {
  WorkbenchAPIClient,
  type InferResponse,
  type InferBody,
  InferRequest,
  components,
} from '@epostbox/keyflowz-api/client'

import { accessToken } from './retrieve-access-token'

const baseUrl = '__TAURI_IPC__' in window ? import.meta.env.VITE_WORKBENCH_API_URL : `${location.origin}`

const Workbench = WorkbenchAPIClient({
  baseUrl,
  credentials: 'include',
})
Workbench.use({
  async onRequest({ request }) {
    if (accessToken) {
      request.headers.set('authorization', `Bearer ${accessToken}`)
    }
    return request
  },
})

export type ContactProperties = components['schemas']['ContactProperties']

export type DocumentAssignmentID = string

export const CreateDocument = Workbench.post('/api/workbench/w/{workspaceId}/documents')
export type CreateDocument = InferResponse<typeof CreateDocument>

export const GetDocument = Workbench.get('/api/workbench/w/{workspaceId}/documents/{documentAssignmentId}/v2')
export type Document = InferResponse<typeof GetDocument>

export const DownloadDocuments = Workbench.post('/api/workbench/w/{workspaceId}/documents/download')
export type DocumentDownloadResponse = InferResponse<typeof DownloadDocuments>
export type DocumentDownloadRequest = InferRequest<typeof DownloadDocuments>

export const DocumentsExtractData = Workbench.post('/api/workbench/w/{workspaceId}/accounting/export')
export type DocumentsExtractDataResponse = InferResponse<typeof DocumentsExtractData>
export type DocumentsExtractDataBody = InferBody<typeof DocumentsExtractData>

export const MarkReadDocuments = Workbench.patch('/api/workbench/w/{workspaceId}/documents/read')
export type MarkReadDocumentsRequest = InferBody<typeof MarkReadDocuments>

export const MarkReadSingleDocument = Workbench.patch('/api/workbench/w/{workspaceId}/documents/read/{docAssnId}')
export type MarkReadSingleDocumentRequest = InferBody<typeof MarkReadSingleDocument>

export const UpdateDocument = Workbench.put(
  '/api/workbench/w/{workspaceId}/documents/{documentAssignmentId}/properties'
)
export type UpdateDocumentRequest = InferBody<typeof UpdateDocument>

export const DeleteDocument = Workbench.delete('/api/workbench/w/{workspaceId}/documents/bulk')
export type DeleteDocumentRequest = InferBody<typeof DeleteDocument>

export const RestoreDocuments = Workbench.patch('/api/workbench/w/{workspaceId}/documents/restore')
export type RestoreDocumentsRequest = InferBody<typeof RestoreDocuments>

export const ApproveDocument = Workbench.put('/api/workbench/w/{workspaceId}/documents/{documentAssignmentId}/approve')
export type ApproveDocumentRequest = InferBody<typeof ApproveDocument>
export type ApproveDocumentResponse = InferResponse<typeof ApproveDocument>

export const CreateSignature = Workbench.post('/api/workbench/w/{workspaceId}/signatures')
export type CreateSignature = InferResponse<typeof CreateSignature>
export type CreateSignatureBody = InferBody<typeof CreateSignature>

export const ListSignatures = Workbench.get('/api/workbench/w/{workspaceId}/signatures')
export type ListSignatures = InferResponse<typeof ListSignatures>

export const GetSignature = Workbench.get('/api/workbench/w/{workspaceId}/signatures/{signatureId}')
export type Signature = InferResponse<typeof GetSignature>

export const UpdateSignature = Workbench.put('/api/workbench/w/{workspaceId}/signatures/{signatureId}')
export type UpdateSignature = InferResponse<typeof UpdateSignature>

export const DeleteSignature = Workbench.delete('/api/workbench/w/{workspaceId}/signatures')
export type DeleteSignatureRequest = InferBody<typeof DeleteSignature>

export const CreateLetterhead = Workbench.post('/api/workbench/w/{workspaceId}/letterheads')
export type CreateLetterhead = InferResponse<typeof CreateLetterhead>
export type CreateLetterheadBody = InferBody<typeof CreateLetterhead>

export const ListLetterheads = Workbench.get('/api/workbench/w/{workspaceId}/letterheads')
export type ListLetterheads = InferResponse<typeof ListLetterheads>

export const GetLetterhead = Workbench.get('/api/workbench/w/{workspaceId}/letterheads/{letterheadId}')
export type Letterhead = InferResponse<typeof GetLetterhead>

export const UpdateLetterhead = Workbench.patch('/api/workbench/w/{workspaceId}/letterheads/{letterheadId}')
export type UpdateLetterhead = InferResponse<typeof UpdateLetterhead>

export const DeleteLetterhead = Workbench.delete('/api/workbench/w/{workspaceId}/letterheads')
export type DeleteLetterheadRequest = InferBody<typeof DeleteLetterhead>

export const CreateAttachment = Workbench.post('/api/workbench/w/{workspaceId}/attachments')
export type CreateAttachment = InferResponse<typeof CreateAttachment>
export type CreateAttachmentBody = InferBody<typeof CreateAttachment>

export const ListAttachments = Workbench.get('/api/workbench/w/{workspaceId}/attachments')
export type ListAttachments = InferResponse<typeof ListAttachments>

export const GetAttachment = Workbench.get('/api/workbench/w/{workspaceId}/attachments/{attachmentId}')
export type Attachment = InferResponse<typeof GetAttachment>

export const UpdateAttachment = Workbench.patch('/api/workbench/w/{workspaceId}/attachments/{attachmentId}')
export type UpdateAttachment = InferResponse<typeof UpdateAttachment>

export const DeleteAttachment = Workbench.delete('/api/workbench/w/{workspaceId}/attachments')
export type DeleteAttachmentRequest = InferBody<typeof DeleteAttachment>

export const GetSearchKey = Workbench.get('/api/workbench/w/{workspaceId}/search/key')
export type GetSearchKeyResponse = InferResponse<typeof GetSearchKey>

export const ListTextTemplates = Workbench.get('/api/workbench/w/{workspaceId}/text-templates')
export type ListTextTemplatesResponse = InferResponse<typeof ListTextTemplates>
export type ListTextTemplatesRequest = InferRequest<typeof ListTextTemplates>

export const CreateTextTemplate = Workbench.post('/api/workbench/w/{workspaceId}/text-templates')
export type CreateTextTemplatesResponse = InferResponse<typeof CreateTextTemplate>
export type CreateTextTemplateRequest = InferBody<typeof CreateTextTemplate>

export const GetTextTemplate = Workbench.get('/api/workbench/w/{workspaceId}/text-templates/{textTemplateId}')
export type GetTextTemplateResponse = InferResponse<typeof GetTextTemplate>

export const UpdateTextTemplate = Workbench.patch('/api/workbench/w/{workspaceId}/text-templates/{textTemplateId}')
export type UpdateTextTemplateResponse = InferResponse<typeof UpdateTextTemplate>
export type UpdateTextTemplateRequest = InferBody<typeof UpdateTextTemplate>

export const DeleteTextTemplate = Workbench.delete('/api/workbench/w/{workspaceId}/text-templates/bulk')
export type DeleteTextTemplateResponse = InferResponse<typeof DeleteTextTemplate>
export type DeleteTextTemplateRequest = InferBody<typeof DeleteTextTemplate>

export const GetContact = Workbench.get('/api/workbench/w/{workspaceId}/addresses/{addressId}')
export type GetContact = InferResponse<typeof GetContact>

export const GetMyContact = Workbench.get('/api/workbench/w/{workspaceId}/addresses/assignment/me')
export type GetMyContact = InferResponse<typeof GetMyContact>

export const CreateContact = Workbench.post('/api/workbench/w/{workspaceId}/addresses')
export type CreateContact = InferResponse<typeof CreateContact>
export type CreateContactRequest = InferBody<typeof CreateContact>

export const UpdateContact = Workbench.patch('/api/workbench/w/{workspaceId}/addresses/{addressId}')
export type UpdateContact = InferResponse<typeof UpdateContact>
export type UpdateContactRequest = InferBody<typeof UpdateContact>

export const DeleteContact = Workbench.delete('/api/workbench/w/{workspaceId}/addresses/bulk')
export type DeleteContact = InferResponse<typeof DeleteContact>
export type DeleteContactRequest = InferBody<typeof DeleteContact>

export const InviteContact = Workbench.post('/api/workbench/w/{workspaceId}/addresses/handshakes/invite')
export type InviteContact = InferResponse<typeof InviteContact>
export type InviteContactRequest = InferBody<typeof InviteContact>

export const BlockContact = Workbench.put('/api/workbench/w/{workspaceId}/addresses/block')
export type BlockContact = InferResponse<typeof BlockContact>
export type BlockContactRequest = InferBody<typeof BlockContact>

export const MoveContacts = Workbench.patch('/api/workbench/w/{workspaceId}/addresses/move/bulk')
export type MoveContacts = InferResponse<typeof MoveContacts>
export type MoveContactsRequest = InferBody<typeof MoveContacts>

export const UpdatePreferredChannel = Workbench.put('/api/workbench/w/{workspaceId}/addresses/default/preference')
export type UpdatePreferredChannel = InferResponse<typeof UpdatePreferredChannel>
export type UpdatePreferredChannelRequest = InferBody<typeof UpdatePreferredChannel>

export const UpdateHandshakePreferredChannel = Workbench.put(
  '/api/workbench/w/{workspaceId}/addresses/{addressId}/preference'
)
export type UpdateHandshakePreferredChannel = InferResponse<typeof UpdateHandshakePreferredChannel>
export type UpdateHandshakePreferredChannelRequest = InferBody<typeof UpdateHandshakePreferredChannel>

export const GetPendingHandshakes = Workbench.get('/api/workbench/w/{workspaceId}/addresses/handshakes/pending/all')
export type GetPendingHandshakesResponse = InferResponse<typeof GetPendingHandshakes>
export type GetPendingHandshakesRequest = InferBody<typeof GetPendingHandshakes>

export const GetPendingHandshakesByAssignment = Workbench.get(
  '/api/workbench/w/{workspaceId}/addresses/handshakes/pending/{docAssnId}'
)
export type GetPendingHandshakesByAssignmentResponse = InferResponse<typeof GetPendingHandshakesByAssignment>
export type GetPendingHandshakesByAssignmentRequest = InferBody<typeof GetPendingHandshakesByAssignment>

export const ListFolders = Workbench.get('/api/workbench/w/{workspaceId}/addresses/folders')
export type ListFolders = InferResponse<typeof ListFolders>

export const CreateFolder = Workbench.post('/api/workbench/w/{workspaceId}/addresses/folders')
export type CreateFolder = InferResponse<typeof CreateFolder>
export type CreateFolderRequest = InferBody<typeof CreateFolder>

export const ShareFolder = Workbench.post('/api/workbench/w/{workspaceId}/addresses/folders/{assignmentId}/share')
export type ShareFolder = InferResponse<typeof ShareFolder>
export type ShareFolderRequest = InferBody<typeof ShareFolder>

export const SharedFolderAssignments = Workbench.get(
  '/api/workbench/w/{workspaceId}/addresses/folders/{assignmentId}/shared/assignments'
)
export type SharedFolderAssignments = InferResponse<typeof SharedFolderAssignments>
export type SharedFolderAssignmentsRequest = InferBody<typeof SharedFolderAssignments>

export const DeleteFolder = Workbench.delete('/api/workbench/w/{workspaceId}/addresses/folders/{folderId}')
export type DeleteFolder = InferResponse<typeof DeleteFolder>

export const MoveFolder = Workbench.patch('/api/workbench/w/{workspaceId}/addresses/folders/move/{folderId}')
export type MoveFolder = InferResponse<typeof MoveFolder>
export type MoveFolderRequest = InferBody<typeof MoveFolder>

export const UpdateFolder = Workbench.patch('/api/workbench/w/{workspaceId}/addresses/folders/{folderId}')
export type UpdateFolder = InferResponse<typeof UpdateFolder>
export type UpdateFolderRequest = InferBody<typeof UpdateFolder>

export const GetAvailableChannels = Workbench.post('/api/workbench/w/{workspaceId}/addresses/channels')
export type GetAvailableChannels = InferResponse<typeof GetAvailableChannels>
export type GetAvailableChannelsRequest = InferBody<typeof GetAvailableChannels>

export const ListSettings = Workbench.get('/api/workbench/w/{workspaceId}/settings')
export type ListSettingsResponse = InferResponse<typeof ListSettings>
export type ListSettingsRequest = InferRequest<typeof ListSettings>

export const ListWorkflows = Workbench.get('/api/workbench/w/{workspaceId}/workflows')
export type ListWorkflowsResponse = InferResponse<typeof ListWorkflows>
export type ListWorkflowsRequest = InferRequest<typeof ListWorkflows>

export const GetWorkflow = Workbench.get('/api/workbench/w/{workspaceId}/workflows/{workflowId}')
export type GetWorkflowResponse = InferResponse<typeof GetWorkflow>
export type GetWorkflowRequest = InferRequest<typeof GetWorkflow>

export const CreateWorkflow = Workbench.post('/api/workbench/w/{workspaceId}/workflows')
export type CreateWorkflowsResponse = InferResponse<typeof CreateWorkflow>
export type CreateWorkflowRequest = InferBody<typeof CreateWorkflow>

export const UpdateWorkflow = Workbench.patch('/api/workbench/w/{workspaceId}/workflows/{workflowId}')
export type UpdateWorkflowResponse = InferResponse<typeof UpdateWorkflow>
export type UpdateWorkflowRequest = InferBody<typeof UpdateWorkflow>

// export const DeleteWorkflow = Workbench.delete('/api/workbench/w/{workspaceId}/workflows/{workflowId}')
// export type DeleteWorkflowResponse = InferResponse<typeof DeleteWorkflow>
// export type DeleteWorkflowRequest = InferBody<typeof DeleteWorkflow>

export const GetGlAccounts = Workbench.get('/api/workbench/w/{workspaceId}/accounting/gl-accounts')
export type GetGlAccountsResponse = InferResponse<typeof GetGlAccounts>
export type GetGlAccountsRequest = InferBody<typeof GetGlAccounts>

export const ImportGlAccounts = Workbench.post('/api/workbench/w/{workspaceId}/accounting/gl-accounts')
export type ImportGlAccountsBody = InferBody<typeof ImportGlAccounts>

export const DownloadGlAccounts = Workbench.get('/api/workbench/w/{workspaceId}/accounting/gl-accounts/download')
export type DownloadGlAccountsResponse = InferResponse<typeof DownloadGlAccounts>
export type DownloadGlAccountsRequest = InferBody<typeof DownloadGlAccounts>

export const GetCreditors = Workbench.get('/api/workbench/w/{workspaceId}/accounting/creditors')
export type GetCreditorsResponse = InferResponse<typeof GetCreditors>
export type GetCreditorsRequest = InferBody<typeof GetCreditors>

export const ImportCreditors = Workbench.post('/api/workbench/w/{workspaceId}/accounting/creditors')
export type ImportCreditorsBody = InferBody<typeof ImportCreditors>

export const DownloadCreditors = Workbench.get('/api/workbench/w/{workspaceId}/accounting/creditors/download')
export type DownloadCreditorsResponse = InferResponse<typeof DownloadCreditors>
export type DownloadCreditorsRequest = InferBody<typeof DownloadCreditors>

export const GetIdentifiers = Workbench.get('/api/workbench/w/{workspaceId}/accounting/identifiers')
export type GetIdentifiersResponse = InferResponse<typeof GetIdentifiers>
export type GetIdentifiersRequest = InferBody<typeof GetIdentifiers>

export const ImportIdentifiers = Workbench.post('/api/workbench/w/{workspaceId}/accounting/identifiers')
export type ImportIdentifiersBody = InferBody<typeof ImportIdentifiers>

export const DownloadIdentifiers = Workbench.get('/api/workbench/w/{workspaceId}/accounting/identifiers/download')
export type DownloadIdentifiersResponse = InferResponse<typeof DownloadIdentifiers>
export type DownloadIdentifiersRequest = InferBody<typeof DownloadIdentifiers>

export const ListInterfaces = Workbench.get('/api/workbench/w/{workspaceId}/workflow-interfaces')
export type ListInterfacesResponse = InferResponse<typeof ListInterfaces>
export type ListInterfacesRequest = InferRequest<typeof ListInterfaces>

export const CreateInterface = Workbench.post('/api/workbench/w/{workspaceId}/workflow-interfaces')
export type CreateInterfaceResponse = InferResponse<typeof CreateInterface>
export type CreateInterfaceRequest = InferRequest<typeof CreateInterface>

export const DeleteInterface = Workbench.delete('/api/workbench/w/{workspaceId}/workflow-interfaces/{interfaceId}')
export type DeleteInterfaceResponse = InferResponse<typeof DeleteInterface>
export type DeleteInterfaceRequest = InferRequest<typeof DeleteInterface>

export const AcceptHandshake = Workbench.post(
  '/api/workbench/w/{workspaceId}/addresses/handshakes/{handshakeId}/accept'
)
export type AcceptHandshakeResponse = InferResponse<typeof AcceptHandshake>
export type AcceptHandshakeRequest = InferRequest<typeof AcceptHandshake>

export const RejectHandshake = Workbench.post(
  '/api/workbench/w/{workspaceId}/addresses/handshakes/{handshakeId}/reject'
)
export type RejectHandshakeResponse = InferResponse<typeof RejectHandshake>
export type RejectHandshakeRequest = InferRequest<typeof RejectHandshake>

export const CompleteOnboarding = Workbench.post('/api/workbench/w/{workspaceId}/onboarding')
export type CompleteOnboardingRequest = InferRequest<typeof CompleteOnboarding>

export const GetOnboarding = Workbench.get('/api/workbench/w/{workspaceId}/onboarding')
export type GetOnboardingResponse = InferResponse<typeof GetOnboarding>

export const GetCostCenters = Workbench.get('/api/workbench/w/{workspaceId}/cost-center')
export type GetCostCentersResponse = InferResponse<typeof GetCostCenters>

export const GetCostCenter = Workbench.get('/api/workbench/w/{workspaceId}/cost-center/{costCenterId}')
export type GetCostCenterResponse = InferResponse<typeof GetCostCenter>

export const CreateCostCenter = Workbench.post('/api/workbench/w/{workspaceId}/cost-center')
export type CreateCostCenterResponse = InferResponse<typeof CreateCostCenter>
export type CreateCostCenterBody = InferBody<typeof CreateCostCenter>

export const UpdateCostCenter = Workbench.patch('/api/workbench/w/{workspaceId}/cost-center/{costCenterId}')
export type UpdateCostCenterResponse = InferResponse<typeof UpdateCostCenter>
export type UpdateCostCenterBody = InferBody<typeof UpdateCostCenter>

export const DeleteCostCenters = Workbench.delete('/api/workbench/w/{workspaceId}/cost-center')
export type DeleteCostCentersResponse = InferResponse<typeof DeleteCostCenters>
export type DeleteCostCentersBody = InferBody<typeof DeleteCostCenters>

export const ContactsBulkImportCheck = Workbench.post(
  '/api/workbench/w/{workspaceId}/addresses/{folderId}/import/check'
)
export type ContactsBulkImportCheckResponse = InferResponse<typeof ContactsBulkImportCheck>
export type ContactsBulkImportCheckBody = InferBody<typeof ContactsBulkImportCheck>

export const ContactsBulkImport = Workbench.post('/api/workbench/w/{workspaceId}/addresses/{folderId}/import')
export type ContactsBulkImportResponse = InferResponse<typeof ContactsBulkImport>
export type ContactsBulkImportBody = InferBody<typeof ContactsBulkImport>

export const CreateContactsBulk = Workbench.post('/api/workbench/w/{workspaceId}/addresses/bulk')
export type CreateContactsBulkResponse = InferResponse<typeof CreateContactsBulk>
export type CreateContactsBulkBody = InferBody<typeof CreateContactsBulk>
